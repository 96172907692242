import './App.css';
import { useState } from 'react';
import headerSeaImage from './imgs/headerSeaImage.png';
import aquaCliqueLogo from './imgs/aquaCliqueLogo.png';
import constructionImage from './imgs/constructionImage.png';
import coelhoPhoto from './imgs/coelhoPhoto.png';
import louizePhoto from './imgs/louizePhoto.png';
import joaoPhoto from './imgs/joaoPhoto.png';
import yuriPhoto from './imgs/yuriPhoto.png';
import apmmLogo from './imgs/apmmLogo.png';
import setecLogo from './imgs/GrupoSETEC_Logotipo.png';
import copyrightIcon from './imgs/copyrightIcon.png';
import { getAnalytics, logEvent } from "firebase/analytics";
import {firebaseApp} from './firebaseConfig';

function App() {
  const analytics = getAnalytics(firebaseApp);
  logEvent(analytics, 'session_start');
  
  const [toggle, setToggle] = useState(false);
  const [showLouizeDescription, setShowLouizeDescription] = useState(false);
  const [showCoelhoDescription, setShowCoelhoDescription] = useState(false);
  const [showJoaoDescription, setShowJoaoDescription] = useState(false);
  const [showYuriDescription, setShowYuriDescription] = useState(false);
  const [showApmmDescription, setShowApmmDescription] = useState(false);
  const [showSetecDescription, setShowSetecDescription] = useState(false);

  function closeDescription() {
    setToggle(false);
    setShowLouizeDescription(false);
    setShowCoelhoDescription(false);
    setShowJoaoDescription(false);
    setShowYuriDescription(false);
    setShowApmmDescription(false);
    setShowSetecDescription(false);
  }


  function defineToggle(person) {

    switch (person) {
      case 'louize':
        setShowLouizeDescription(!showLouizeDescription);
        break;
      case 'coelho':
        setShowCoelhoDescription(!showCoelhoDescription);
        break;
      case 'joao':
        setShowJoaoDescription(!showJoaoDescription);
        break;
      case 'yuri':
        setShowYuriDescription(!showYuriDescription);
        break;
      case 'apmm':
        setShowApmmDescription(!showApmmDescription);
        break;
      case 'setec':
        setShowSetecDescription(!showSetecDescription);
      break;
    }

    setToggle(!toggle);
  }


  return (
    <div className="page">

      <div className={`
            ${toggle ? "description-background" : "hidden-element"}
        `}>

        <div className="description-container">

          <div className="exit-container">
            <div
              onClick={() => closeDescription()}
              className="exit-circle">
              <p className="exit-button">X</p>
            </div>
          </div>


          <div
            className={`
                        individual-description-container
                        ${!showLouizeDescription ? "hidden-element" : null}
                    `}
          >

            <img
              onClick={() => defineToggle('louize')}
              className="participant-photo-description"
              src={louizePhoto}
              alt="Louize, participante do projeto"
            />

            <p className="participant-description">
              Dra. Louize Oldrini, advogada especializada em Direito Marítimo Aquaviário.
              No período de 2012 a 2017 trabalhou na Diretoria de Portos e Costas exercendo o cargo de assessora do
              Ensino Profissional Marítimo, no Departamento de Pessoal Aquaviário, no Setor de Certificação e Carreira,
              participando ativamente na implantação das emendas de Manilla oriundas da Convenção STCW/78, como
              emendada e algumas das atualizações da NORMAM-13/DPC.
                    </p>

          </div>


          <div
            className={`
                        individual-description-container
                        ${!showCoelhoDescription ? "hidden-element" : null}
                    `}
          >
            <img
              onClick={() => defineToggle('coelho')}
              className="participant-photo-description"
              src={coelhoPhoto}
              alt="Daniel, participante do projeto"
            />

            <p className="participant-description">
              Daniel Coelho de Oliveira, graduando no curso de Bacharelado em Sistemas de Informação,
              na Universidade Federal do Estado do Rio de Janeiro. Trabalha com desenvolvimento
              web fullstack, utilizando as tecnologias C# (.Net Core e Asp Net),
              javascript (React Native, React) e atuando também na parte de administração de banco de
              dados relacional (sqlserver e mysql) e não relacional (firebase). Possui cargo de diretor executivo
              na empresa júnior da UNIRIO, do curso de BSI, realizando, entre outras coisas, treinamento
              de base para as equipes ingressantes em javascript, css e html.
                    </p>
          </div>


          <div
            className={`
                        individual-description-container
                        ${!showJoaoDescription ? "hidden-element" : null}
                    `}
          >
            <img
              onClick={() => defineToggle('joao')}
              className="participant-photo-description"
              src={joaoPhoto}
              alt="João, participante do projeto"
            />

            <p className="participant-description">
              João Vítor Oliveira Ferreira, desenvolvedor de sistemas web fullstack

              Graduando de Sistemas de Informação na UNIRIO,
              atua com desenvolvimento de sistemas web fullstack no mercado desde 2017, usando as tecnologias Javascript ( Node.js, React,React Native, Vue.js) e C# (.net core, .net framework), além de administrar bancos de dados ( MongoDB, MySQL, MSSQL)
              e participar de projetos com viés social, como o Maria da Penha Virtual, ferramenta de auxílio a vítimas de violência doméstica, utilizada pelo TJRJ.
              Como pesquisador graduando pela UNIRIO, participou de grupos de pesquisa da área de Engenharia de Software, sendo atuante em pesquisas com foco para o ensino e para o entendimento de relacionamento de empresas através de sistemas.
                   </p>
          </div>


          <div
            className={`
                        individual-description-container
                        ${!showYuriDescription ? "hidden-element" : null}
                    `}
          >
            <img
              onClick={() => defineToggle('yuri')}
              className="participant-photo-description"
              src={yuriPhoto}
              alt="Yuri, participante do projeto"
            />

            <p className="participant-description">
              Yuri Farias, graduando no curso de Bacharelado em Sistemas de Informação,
              na Universidade Federal do Estado do Rio de Janeiro. Trabalha com desenvolvimento
              web fullstack.
                    </p>
          </div>


          <div
            className={`
                        individual-description-container
                        ${!showApmmDescription ? "hidden-element" : null}
                    `}
          >
            <img
              onClick={() => defineToggle('apmm')}
              src={apmmLogo}
              className="supporters"
              alt="logo da Associacao dos Profissionais da Marinha Mercante Brasileira"
            />

            <p className="participant-description">
              A Associação dos Profissionais da Marinha Mercante Brasileira é uma instituição de natureza civil, de direito privado, sem fins lucrativos e regida por estatuto próprio e pelas leis aplicáveis.
                    </p>
          </div>
          <div
            className={`
                        individual-description-container
                        ${!showSetecDescription ? "hidden-element" : null}
                    `}
          >
            <img
              onClick={() => defineToggle('setec')}
              src={setecLogo}
              className="supporters"
              alt="logo do grupo SETEC"
            />

            <p className="participant-description">
            O GRUPO SETEC é uma organização especializada na prestação de serviços de assessoria, consultoria e treinamentos nas áreas de Qualidade, Segurança do Trabalho, Meio Ambiente e Saúde Ocupacional.   
            </p>
          </div>
        </div>
      </div>


      <div
        className={`
              ${toggle ? "page-background" : null}
          `}
      >


        <div className="header-container">
          <img id="headerSeaImage" src={headerSeaImage} alt="ondas do mar" />
          <img id="aquaCliqueLogo" src={aquaCliqueLogo} alt="Simbolo da AquaClique" />

          <h1 className="app-name-title">
            AquaClique
          </h1>
        </div>


        <div className="content-container">


          <div className="construction-container">

            <img className="construction-image" src={constructionImage} alt="em construcao" />

            <div className="construction-description-container">
              <h2 id="construction-title">
                Em construção...
              </h2>

              <p className="construction-description">
                Acompanhe nossa página e envie sugestões para enriquecer nosso conteúdo!
                Em breve estaremos anunciando nesse portal a data de lançamento e muitas outras novidades!
                <br></br>
              <a className="form-link" href="https://forms.gle/ypkvh1v4SY4mZ1ve6" target="_blank">Clique aqui</a> para acessar o
               formulario de intenção sobre o aplicativo e nos ajudar a fazer o melhor serviço para você!</p>
            </div>

          </div>


          <div className="about-container">

            <h2 className="subtitle">Sobre o projeto</h2>

            <p className="about-description">
              O compêndio de normas que regem os aquaviários em nosso país é um desafio para todos que
              aqueles que escolheram esse segmento. O Ordenamento Jurídico que norteia os trabalhadores Marítimos, Fluviários e
              Pescadores, assim como as seções de Câmara e Saúde, vão muito além das Convenções em que nosso país é
              signatário e também das normas internas recepcionadas e publicadas pela Autoridade Marítima Brasileira.  E essa complexidade faz com que esse segmento, em geral, se torne muitas vezes incompreendido e pouco
              atrativo.
            </p>

            <p className="about-description">
              Pensando nisso é que a Aquatrip Assessoria Especializada em parceria com uma
              equipe qualificada de desenvolvedores e colaboradores lançará em breve um aplicativo capaz de reunir o
              maior número de informações possíveis destes aquaviários, na palma de sua mão, possibilitando que esses trabalhadores possam gerir suas próprias documentações, carreiras e muitos outros apontamentos que fazem parte de
              sua vida marítima, tudo isso de acordo com as novas regras sobre LGPD e proteção de dados.
            </p>

          </div>


          <div>

            <h2 className="subtitle">
              Integrantes do projeto
            </h2>
            <p className="hint">*Clique para ver a descrição</p>

            <div className="participants-container">

              <img
                onClick={() => defineToggle('louize')}
                className="participant-photo"
                src={louizePhoto}
                alt="Louize, participante do projeto"
              />
              <img
                onClick={() => defineToggle('coelho')}
                className="participant-photo"
                src={coelhoPhoto}
                alt="Daniel, participante do projeto"
              />
              <img
                onClick={() => defineToggle('joao')}
                className="participant-photo"
                src={joaoPhoto}
                alt="João, participante do projeto"
              />
              <img
                onClick={() => defineToggle('yuri')}
                className="participant-photo"
                src={yuriPhoto}
                alt="Yuri, participante do projeto"
              />

            </div>

          </div>


        </div>

        <div className="supporters-container">

          <h2 className="subtitle">Apoiadores do projeto</h2>
          <p className="hint">*Clique para ver a descrição</p>

          <img
            onClick={() => defineToggle('apmm')}
            className="supporters"
            src={apmmLogo}
            alt="logo da Associacao dos Profissionais da Marinha Mercante Brasileira"
          />
          <img
            onClick={() => defineToggle('setec')}
            className="supporters"
            src={setecLogo}
            alt="logo do grupo SETEC"
          />

        </div>

        <div className="footer-container">
          <div className="copyright-description-container">
            <img src={copyrightIcon} alt="icone de copyright" />
            <span className="copyright-description">
              Copyright 2021 by AquaClique. All rights reserved.
          </span>
          </div>


        </div>

      </div>


    </div>

  );
}

export default App;
